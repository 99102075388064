<script setup lang="ts">
import type { ILeaseDocumentFieldSection } from '@register'
import { useFieldSelection } from '@register/components/Review/composables'

const props = defineProps<{ section: ILeaseDocumentFieldSection }>()

// Field selection
const {
  fieldSelectionState,
  snippetRowPosition,
  sectionContainerDiv,
  unselect,
  isSelectedSection,
} = useFieldSelection()
const numberOfFields = computed(() => props.section.fields?.length ?? 0)
</script>

<template>
  <div>
    <div class="flex w-full">
      <h2 class="flex w-full items-center text-gray-200">
        <Icon
          :key="'icon' + section.id"
          :name="'review/' + section.id"
          class="mr-2 text-xl text-gray-600"
          filled
        />
        {{ section.name }}
      </h2>

      <div class="flex w-20">
        <LeaseReviewFieldsProgress :fields="section.fields ?? []" />
      </div>
    </div>

    <div
      id="section-container"
      ref="sectionContainerDiv"
      class="@container flex w-full"
    >
      <div
        class="mt-4 grid w-full grid-cols-1 gap-x-8 gap-y-6"
        :class="{
          '@lg:grid-cols-2': numberOfFields === 2,
          '@lg:grid-cols-2 @3xl:grid-cols-3': numberOfFields === 3,
          '@lg:grid-cols-2 @3xl:grid-cols-3 @6xl:grid-cols-4':
            numberOfFields >= 4,
        }"
      >
        <LazyLeaseReviewField
          v-for="field in section.fields"
          :key="field.id"
          :field="field"
          :large="section.id === 'Use'"
        />

        <!-- Field snippet -->
        <LazyLeaseReviewPdfSnippet
          v-if="isSelectedSection(section)"
          :state="fieldSelectionState"
          :row-position="snippetRowPosition"
          @close="unselect"
        />

        <LazyLeaseReviewLinkButtons :links="section.links ?? []" />
      </div>
    </div>
  </div>
</template>
